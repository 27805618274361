import React from 'react';
import { graphql, useStaticQuery } from 'gatsby'
import Img from "gatsby-image"

import MainLayout from '../layouts/mainlayout/MainLayout';
import PageHeader from '../components/pageheader/PageHeader';

function KnowGod() {
  const data = useStaticQuery(graphql`
		query Jesus {
			jesus:file(relativePath: {eq: "jesus.jpg"}) {
				childImageSharp {
				  fluid {
					...GatsbyImageSharpFluid
				  }
				}
			},
      need_jesus_1:file(relativePath: {eq: "need-jesus-1.png"}) {
				childImageSharp {
				  fluid {
					...GatsbyImageSharpFluid
				  }
				}
			},
      need_jesus_2:file(relativePath: {eq: "need-jesus-2.png"}) {
				childImageSharp {
				  fluid {
					...GatsbyImageSharpFluid
				  }
				}
			},
      need_jesus_3:file(relativePath: {eq: "need-jesus-3.png"}) {
				childImageSharp {
				  fluid {
					...GatsbyImageSharpFluid
				  }
				}
			},
      need_jesus_4:file(relativePath: {eq: "need-jesus-4.png"}) {
				childImageSharp {
				  fluid {
					...GatsbyImageSharpFluid
				  }
				}
			}
		}	  
	`)
  return (
    <MainLayout title="Know God">
      <PageHeader title="Know GOD" />
      <div className='general-section'>
        <h2>Who is Jesus?</h2>
        <div className='ms-3'>
          <p>
            <strong>Who do you say Lord Jesus is?</strong> Your response to Him will determine not only your values and lifestyle but your eternal destiny as well.
          </p>
          <p>
            Some thought He was a wise man or a great prophet. Others thought He was a madman. Still others couldn't decide or didn't care. Some are content to think of Him as a great moral teacher. Even His enemies understood His claims to deity. That's why they tried to stone Him to death and eventually had Him crucified. The world calendar and the BC/AD dating system hang on the birth of Lord Jesus Christ. He is indeed a man of History! Jesus is more than a historical figure or a great teacher who once lived.
          </p>
          <div className='mx-auto mb-3' style={{ width: "50vw" }}>
            <Img fluid={data.jesus.childImageSharp.fluid} />
          </div>
          <p>
            Jesus is the epitome of revolutionary love, heavenly forgiveness and divine power!
          </p>
          <p>
            His stint on Earth was packed with miracles, the greatest of which were his own birth and resurrection. The novelty of these events has surpassed the test of time, and buried every pallbearer who attempted to disprove the divinity of Jesus.
          </p>
          <p>
            His many miracles on Earth were a speck in comparison to his ultimate mission, which he accomplished on the cross, in the grave and on the day on resurrection. The blood, shed by Jesus on the cross is our only hope for forgiveness.
          </p>
          <p>
            He conquered death so that we might live. We don't have to earn His love and salvation, it's a free gift available to all who believe. Jesus wants to have a personal relationship with us. All we have to do is receive it by faith, and come to Him the way we are.
          </p>
          <p>
            C.S Lewis, a great novelist says, “The things that He says are very different from what any other teacher or guru or God men have ever said in history. Others have said, “ This is the way you ought to go, this is the truth about yourselves but Lord Jesus says, “I am the Way, the Truth and the Life.” He says, “No man can reach absolute reality, except through Me. Come to Me everyone who is carrying a heavy load, I will set that right. Your sins are wiped out, I can do that, I am Life. And finally, do not be afraid, I have overcome. The whole universe.”
          </p>
          <p>
            Discover the truth and discover true freedom in your life.
          </p>
        </div>

        <h2>Why Do I need Jesus?</h2>
        <div className='ms-3'>
          <h5>1. Man was separated from God by sin:</h5>
          <div className='ms-3'>
            Are you happy living for yourself, getting the most toys, winning all the trophies, and then one day die, eternally separated from God? <br />
            <strong><em><q>For all have sinned, and come short of the glory of God.</q></em></strong> - Romans 3:23
          </div>

          <div className='my-3' style={{ width: "25vw" }}>
            <Img fluid={data.need_jesus_1.childImageSharp.fluid} />
          </div>

          <h5>2. MAN cannot save himself:</h5>
          <div className='ms-3'>
            Or do you want a life full of purpose and hope? Christ has paid the price to make a way for you to come back to God. <strong><em><q>For it is by grace you have been saved, through faith and this is not from yourselves, it is the gift of God.</q></em></strong> - Ephesians 2:8
          </div>

          <div className='my-3' style={{ width: "25vw" }}>
            <Img fluid={data.need_jesus_2.childImageSharp.fluid} />
          </div>

          <h5>3. Only JESUS can save:</h5>
          <div className='ms-3'>
            God, the One who created you, has a purpose for your life and wants to give you a new beginning. Jesus answered, <strong><em><q>I am the way and the truth and the life. No one comes to the Father except through me.</q></em></strong> - John 14:6
          </div>

          <div className='my-3' style={{ width: "25vw" }}>
            <Img fluid={data.need_jesus_3.childImageSharp.fluid} />
          </div>

          <h5>4. Repent and believe in JESUS:</h5>
          <div className="ms-3">
            If you are ready to admit that you believe in Jesus Christ, the God of second chances is waiting to give it to you. <strong><em><q>Salvation is found in no one else, for there is no other name under heaven given to mankind by which we must be saved.</q></em></strong> - Acts 4:12
          </div>

          <div className='my-3' style={{ width: "25vw" }}>
            <Img fluid={data.need_jesus_4.childImageSharp.fluid} />
          </div>

          <h5>What should I do to be saved?</h5>
          <div className="ms-3">
            <p>
              Welcome the Lord Jesus Christ into your life & Experience a divine transformation in you.
            </p>
            <p>
              The Bible says: <strong><em><q>If you confess with your mouth, Jesus is Lord, and Believe in your heart that God raised him from the dead, you will be saved.</q></em></strong> - Romans 10:9
            </p>
            <p>
              Yet, you may say, <strong><em><q>You don't know what mistakes I've done. You don't know the life I've lived. You don't know the people I've hurt.</q></em></strong> Maybe we don't but know this, Nothing you've done is beyond the mercy of God. If you get knocked down, all you have to do is get back up and believe in the promises of Lord Jesus! <strong><em><q>As many as received Him, to them He gave to become children of God, even to those who believe in His name.</q></em></strong> - John 1:12
            </p>
          </div>
        </div>
        <h2>This is how you receive Lord Jesus Christ into your LIFE:</h2>
        <div className="ms-3">
          <ol>
            <li>Admit your need for a Saviour.</li>
            <li>Be willing to turn from your sins.</li>
            <li>Believe that Jesus Died for you on the Cross and Rose alive from the dead.</li>
            <li>Invite Lord Jesus to come in and take control of your life.</li>
            <li>All you have to do is sincerely cry out to God with the following short prayer.</li>
          </ol>
          <div style={{
            borderLeft: "5px solid #ccc",
            background: "rgba(235,234,235,1)",
            padding: "20px 10px",
            fontWeight: "400",
            fontSize: "1.3rem"
          }}>
            “Father, I believe that your son, Jesus Christ, died for my sins, was resurrected from the dead, is alive, and hears my prayer. I confess that I am a sinner and I need your forgiveness. I now invite you to come into life and take over everything. I want to trust you only and follow you all the days of my life as my Lord and Savior. In Jesus' name I pray, Amen.”
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default KnowGod